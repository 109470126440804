import React from "react"
import SEO from "../components/SEO"

const NotFoundPage = () => (
  <div>
    <SEO title="404: Not found" />
    <h1>404: 页面未找到</h1>
  </div>
)

export default NotFoundPage